.wholeGame{
    display:flex;
    flex-direction: column;
    min-height:0;
    height:100%;
   
  /*  background-color: aqua;*/
    
}

.showMe{
    background-color: aquamarine;
   
}



.wholeScreen{
    
   min-height:100vh;
    display:flex;
    flex-direction:column;
    min-width:fit-content
}

.contentWrapper{
    flex:1;
    display: flex;
    flex-direction: column;
}


body{
    margin:0;
   
}




.gameHeader{
    margin:24px;
    
}

.gameButtons{
    font-size:1.125rem;
    
}



h1{
    font-size:2rem
}
h2{
    font-size:1.5rem
}
h3{
    font-size:1.3rem
}
h4{
    font-size:1rem
}
h5{
    font-size:.8rem
}
h6{
    font-size:.7rem
}

p{
    font-size:1rem;
}

.notFound{
    text-align:center;
}


