@use 'sass:color';

.myTabs.nav-tabs{
    --bs-nav-tabs-border-width:2px;

    border-top-color:transparent;
    border-right-color:transparent;
    border-left-color:transparent;
    border-color: rgb(131, 222, 250);
    

    font-size: 1.5rem;
}


.myTabs.nav-tabs .nav-link{
    border-width:2px;
    color:black;
    border-top-color:transparent;
    border-right-color:transparent;
    border-left-color:transparent;
    border-bottom-color: rgb(131, 222, 250);
}

.myTabs.nav-tabs .nav-link:hover{
    
   
    border-color: rgb(131, 222, 250);
}



.myTabs.nav-tabs .nav-link.active{
   
    background-color: rgb(131, 222, 250);
}


.tab1{
  font-size: 1.25rem;
  
}

/*
.medal{
   
} */

$gold-medal: #f9ad0e;
$silver-medal: #d1d7da;
$bronze-medal: #df7e08;
$neutral-medal: #d1d7da;
$platinum-medal: #9cd9f7;

.quiz-medal {
  position: relative;
  margin-top: 8px;
  margin-left:16px;
  margin-bottom:24px;
 
}

.quiz-medal__circle {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 500;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  color: white;
  text-align: center;
  line-height: 46px;
  vertical-align: middle;
  position: relative;
  border-width: 0.2em;
  border-style: solid;
  z-index: 1;

  // Default colors
  box-shadow: inset 0 0 0 darken($neutral-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: lighten(adjust-hue($neutral-medal, 10), 10%);
  text-shadow: 2px 2px 0 darken($neutral-medal, 20%);
  background: linear-gradient(to bottom right, $neutral-medal 50%, darken($neutral-medal, 5%) 50%);

  &.quiz-medal__circle--gold {
    box-shadow: inset 0 0 0 darken($gold-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: lighten(adjust-hue($gold-medal, 10), 10%);
    text-shadow: 0 0 4px darken($gold-medal, 20%);
    background: linear-gradient(to bottom right, $gold-medal 50%, darken($gold-medal, 5%) 50%);
  }

  &.quiz-medal__circle--silver {
    box-shadow: inset 0 0 0 darken($silver-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: lighten(adjust-hue($silver-medal, 10), 10%);
    text-shadow: 0px 0px 4px darken($silver-medal, 20%);
    background: linear-gradient(to bottom right, $silver-medal 50%, darken($silver-medal, 5%) 50%);
  }

  &.quiz-medal__circle--bronze {
    box-shadow: inset 0 0 0 darken($bronze-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: lighten(adjust-hue($bronze-medal, 10), 10%);
    text-shadow: 0 0 4px darken($bronze-medal, 20%);
    background: linear-gradient(to bottom right, $bronze-medal 50%, darken($bronze-medal, 5%) 50%);
  }

  &.quiz-medal__circle--platinum {
    box-shadow: inset 0 0 0 darken($platinum-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: lighten(adjust-hue($platinum-medal, 10), 10%);
    text-shadow: 0 0 4px darken($platinum-medal, 20%);
    background: linear-gradient(to bottom right, $platinum-medal 50%, darken($platinum-medal, 5%) 50%);
  }
}

.quiz-medal__ribbon {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 6px 10px;
  width: 0;
  height: 20px;
  top: 50px;
}

.quiz-medal__ribbon--left {
  border-color: #FC402D #FC402D transparent #FC402D;
  left: 8px;
  transform: rotate(20deg) translateZ(-32px);
}

.quiz-medal__ribbon--right {
  left: 28px;
  border-color: darken(#FC402D, 10%) darken(#FC402D, 10%) transparent darken(#FC402D, 10%);
  transform: rotate(-20deg) translateZ(-48px);
}

.bronze{
    background-color: burlywood;
}

.silver{
    background-color: rgb(176, 175, 173);
}

.gold{
    background-color: rgb(222, 214, 74);
}

.platinum{
    background-color: rgb(171, 236, 243);
}

.allMedals{
  margin-top:16px;
}

.flexMedal{
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  background-color: rgb(170, 202, 253);
  align-items: center;
  border-radius: 16px;
  border: 6px solid rgb(102, 158, 247);
}

.fitContent{
  width: fit-content;
}

.halfTransparent{
  opacity: .5;
}

.noBottom{
  margin-bottom: 0px;
  
}

.scoreHeading{
  font-size:1.5rem
}

.scoreList{
  font-size:1.5rem;
  border-bottom: orange 2px solid;
}

.flexScores{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}