.chooseButton{
   
    margin:16px;
    font-size: 2rem;
    padding: 10px 26px;
    color: black;
    border-radius:8px;
    border-color: black;
   
}

.expand{
  
    width:100%
}

.questionMenu{
    background-color: rgb(131, 222, 250);
    padding:4px 8px 16px 8px;
    border-radius: 8px;
    margin-bottom: 24px;
    margin-right: 16px;
}

.flexChoose{
   
    display: flex;
  

    position: relative;
    flex-direction:row;
    column-gap: 16px;
    
    min-height:fit-content;
    justify-content: center;
    margin: 24px 0px 0 0px;

}


.chooseButton.green{
    width: calc(100% - 32px);
    background-color:  #42ed42;
   margin-top:0px;
}

.chooseButton.blue{
    width: calc(100% - 32px);
    background-color: #40e4ed;
   
}


.chooseButton.red{
    width: calc(100% - 32px);
    background-color: #f7b32a;
    
}

.chooseButton.gold{

    background:linear-gradient(to bottom, #e2ca13, #f9f2c0, #e2ca13);

    box-shadow: inset 0px 1px 0px rgba(255,255,255,1),0px 1px 3px rgba(0, 0, 0, 0.75);

    border: 1px solid #bcc6cc;
    
    margin-bottom: 24px;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.specialButton {
    position: relative;
    
    overflow: hidden;
}

.sheen:after{
    content: '';
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255,255,255,0.5) 50%, rgba(229, 172, 142, 0));
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }
  /*This activates the sheen once the button has been hovered*/
  .sheen:hover::after, .specialButton:focus::after {
    animation: sheen 1s forwards;
  }
  @keyframes sheen {
    100% {
      transform: rotateZ(60deg) translate(1em, -9em);
    }
  }

 

.test1{

background-color: #58edca;
}


 .localChoose   p{
        font-size:1.25rem
}

.questionLabel{
    margin-right:1rem;
}

label{
    
    font-size: 1.25rem;
    
    
}

input[type=radio] {
    display:inline;
    vertical-align: middle;
    
    width: 1.25rem;
    height: 1.25rem;
    
 
    margin-left:0px;
   
    
}

#ten{
    margin-left:.5rem;
}

#twenty{
    margin-left:.5rem;
}

#thirty{
    margin-left:.5rem;
}


.radioText{
    vertical-align: middle;
}


.textLeft{
    margin:.5rem;
  
}

@media (max-width: 768px) {

    .flexChoose{
        column-gap: 4px;
    }

    .chooseButton{
        font-size:1.5rem;
    }
}
