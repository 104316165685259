

.header{
    position:sticky;
    margin: 0;
    font-size: 1.125rem;
    padding: 12px 24px 8px 24px;
    background-color: rgb(131, 222, 250);

}


.testMe{
 background-color: aliceblue;
}

.header1{

  font-size: 1.25rem;
  
}

.header2{
    width:100%;
    font-size: 1.125rem;
}

.verticalText{
  align-items: center;
  display: flex;

  width:fit-content;
}


.logo{
  color: #0d6efd;
  margin-right: 24px;
 
}

.navMenu{
  text-decoration: none;
  color:black;
  font-size:1.5rem;
}

.navMenu:hover{
  color:#0d6efd;
}

.navbar-toggler:focus {
  text-decoration: none; 
  outline: 0;
  box-shadow: 0;
}

a.navMenu.nav-link:hover {
  color:blue;
}


.loginHeader{

    font-size: 1.125rem;
}

.homeButton{
    width:fit-content;
    font-size:1.125rem;
}

.playHome{
  margin-bottom:16px;
  font-size:1.75rem;
}


h2{
  margin-bottom:16px;
}

.transparent{
  visibility: hidden;
}

.centerText{
  text-align: center;
}

.dogList{
  margin-top:16px;
  width:100%;
  
  border: 3px solid rgb(131, 222, 250);

  padding:8px;
  white-space: nowrap;
 
  
}


.dropdown-item.active{
  background-color: white;
  color:black;
}

.dropdown-item:hover{
  background-color: #cfe2ff !important;
  color:black;
}


.homeFlex{
  display: flex;
  flex-direction: row;
  min-height:0px;
  justify-content: space-between;

  height:100%;
}

#showDog > input{
  width: 100%;
  padding-left: 8px;
}

#footer{
  margin-top:auto;
  height:fit-content;
  padding: 24px 24px 12px 24px;
  
  background-color: #4e89e2;
}


.footerText{
  color:white;
}

.youtubeBox{
  background-image: url(../resources/yt_crop_logo.png);
 
  width:45px;
  height:30px;
  background-size:cover;

}

.youtubeBox:hover{
  background-image: url(../resources/yt_logo_dark3.png);
}

.twitterBox{
  background-image: url(../resources/twitter.svg);
 
  width:25px;
  height:30px;
  background-size:contain;
  background-repeat: no-repeat; 
}

.twitterBox:hover{
  background-image: url(../resources/twitter_dark.svg);

}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.nav-link:active{
  color:black;
}

.nav-link:hover{
  color: black;
}

@media(max-width:48rem){

  .homeFlex{
    flex-wrap:wrap;
    justify-content: flex-start;
  }

  .homeFlex > :nth-child(1){
    order: 2;
  }

  .homeSummary{
    margin-bottom:.75rem;
  }

}

@media(max-width:768px){

  .navMenu{
    margin-bottom:8px;
  }

  .header2{
    margin-bottom:8px;
  }
}

