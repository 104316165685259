:root{
    --margin1: 24px;
 
}

.image1{
	max-height:100%;

    width:auto;
	position:relative;
    
    
    justify-content: space-between;
	
    margin: 0 var(--margin1) 0 var(--margin1);
    box-sizing: border-box;

    display: flex;
    box-sizing: border-box;
}



.dogImage{

    max-height:100%;
    max-width:100%;

    object-fit: contain;
    
    border-radius:16px;
   
    position: relative;
    
    box-sizing: border-box;
    
    border: 2.4px solid #000000;
    
}

.dogContainer{
    
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
   
    max-height:500px;
    max-width:100%;
}



.tracker{
    position: absolute;
    top:0;
    left:0;
    max-width:100%;
    font-size:1.5rem;
    display: inline-block;
   
    
}

.rightInfo{
    position: absolute;
    top:0;
    right:0;
    max-width:100%;
    font-size:1.5rem;
    display: inline-block;
    text-align: right;
}

.hiddenTracker{
  visibility: hidden;
  font-size:1.5rem;
}

.trackerBox{
    white-space:nowrap;
    position: relative;
    width:fit-content;
    height:fit-content;
    vertical-align: top;
    display: inline-block;
    margin-bottom:8px;
    margin-right:16px;
    font-size:1.5rem;
    
}

.trackerBoxRight{
    white-space:nowrap;
    position: relative;
    width:fit-content;
    height:fit-content;
    vertical-align: top;
    display: inline-block;
    margin-bottom:8px;
    margin-left:16px;
    font-size:1.5rem;
    
}

#choice-container{
    min-height:fit-content;
    min-width:fit-content;
    
	position:relative;
	background-color:#dfe7eb;
	text-align:center;
	border-radius:16px;
	display:grid;
	grid-template-columns: repeat(2, 1fr);
    grid-auto-rows:1fr;
    margin: var(--margin1);
    
	
	border: 2.4px solid #000000;
    
    box-sizing: border-box;
    container-type:inline-size;

    gap:20px;
    padding:20px;
}

.buttonTop{
	background-color:#2dbffd;
	border-radius:12px;
	font-size:1.75rem;
    line-height:2.25rem;
    padding: 8px;
    color:black;
    border-color: black;
}

.buttonTop.wrong{
    background-color: #f24b29;
    transition:background-color .5s;
}

.buttonTop.right{ 
     background-color: #2de333;
     transition:background-color .25s;
}

.buttonTop.orange{
    background-color: #e3a92d;
    transition:background-color .25s;
}


@media(min-width: 48rem){
    .gameDiv{
        min-height:0;
    }

    .image1{
        min-height:200px;
    }

    
}


@media (max-width: 62.5rem){


    .buttonTop{
        font-size:1.5rem;
        line-height:2rem;
    }

    #choice-container{
        margin:16px var(--margin1) 16px var(--margin1);
        gap:16px;
        padding:16px;
    }
    
}

@media(max-width:48rem){

    .buttonTop{
        font-size:1.25rem;
        line-height:1.75rem;
    }

    .image1{
        
        flex-wrap:wrap;
        
    }


    .image1 > :nth-child(2){
        order: 3;
        
    }

    .dogContainer{
        max-height: 300px;
        width:100%;
    }

    .trackerBox{
        margin-right:8px;
    }

    .trackerBoxRight{
        margin-left:8px;
    }

}

    .loadingScreen{
        
        margin: 0 var(--margin1) var(--margin1) var(--margin1);
        height: fit-content;
        font-size:2rem;
    }



    .results{
        margin: 0 var(--margin1) var(--margin1) var(--margin1);

        justify-content: center;
       
        box-sizing: border-box;
        display:flex;
        
        min-height:fit-content;
        min-width:fit-content;
        
       height:100%;
        
    }

    .innerResults{
        

        background-color: rgb(131, 222, 250);
        padding: 16px;
        padding-left:24px;
        padding-right:24px;
        border-radius: 8px;
       
        display:flex;
        flex-direction: column;
        min-width:fit-content;
        min-height:fit-content;
        flex-grow:.3;
       
        position:relative;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height:100%;
    }




    .resultsText{
        font-size:1.5rem;
        
    }

    .resultsColor{
        color: #0017ff;
    }

