.loginButton{
    width:fit-content;
    font-size: 1.125rem;
}

.container{
    vertical-align: middle;
    
}

.warning{
    color:brown;
    position:absolute;
    margin-left:16px;
    margin-right:16px;
    font-size:1.125rem;
}

.gaplessWarning{
    color:brown;
    font-size:1.125rem;
}

.centerText{
    text-align: center;
}

.centerBox{

   margin:auto;
   
}

.finishHeader{
    text-align: center;
    margin-bottom:16px;
}

#submissionText{
    color:brown;
    text-align: center;
    font-size:1.125rem;

}

.centerExtra{
   font-size: 1.125rem;
    text-align: center;
    
}

.smallText{
    font-size:1.125rem;
}

.centerBtn{
    text-align: center;
}

.buttonFont{
    font-size: 1.25rem;
    width: 100%;
}

.field{
    width:100%
}

.inlineBox{
    position: absolute;
    display: inline-block;
    left:0;
    right:0;
}

.transparent{
    visibility: hidden;
}

.topText{
    vertical-align: baseline;
    
}


.centerDiv{
    margin:auto;
    width:fit-content;
}


.inline{
    position:absolute;
}

.flexMenu{
    
    margin:24px ;
    max-width: 100%;
}





.fitButton{
    width:fit-content;
}

.leftMenu{
    width:fit-content;
    
    
    margin-top:16px;
   
}

.fitContent{
    width:fit-content;
  
}

.center{
    margin:auto;
}

.rightMenu{
    background-color: rgb(131, 222, 250);
    padding-left:36px;
    padding-right:36px;
    padding-top:24px;
    padding-bottom:24px;
    border-radius: 8px;
    
    
}

.profileColumn{
   width:fit-content;
   min-width:0;
}



.container-fluid .justifyFlex{
    flex-wrap: nowrap;
    
    
}

@media (max-width: 768px) {

    .container-fluid .justifyFlex{
        flex-wrap: wrap;
    }

    .profileColumn{
        width:100%;
     }
}

.col-md-3{
    min-width:fit-content;
}

.lowPad{
    padding:0 !important
}

.normalButton{
    color:white;
    background-color: #0d6efd;
}

.normalButton:hover{
    color:white;
    background-color: #0b5ed7;
}

.normalButton:active{
    color:white;
    background-color: #0a58ca;
}

.normalButton:focus{
    color:white;
}



  .blackLink{
    text-decoration: none;
    color:black;
    
    
  }
  
  .blackLink:hover{
    color:#0d6efd;
  }

  #menuParent{
    align-items: flex-end !important;
  }


@media (min-width: 768px) {

    .waitSmall{
        visibility:hidden;
    }

    .h-md-100 {
        height: 100%;
    }



}





.clearBackground{
    background-color: white;
}

.correct{
    color:rgb(2, 147, 26);
}



